body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    YuGothic, "Yu Gothic Medium", "Hiragino Sans", "Hiragino Kaku Gothic ProN",
    Meiryo, sans-serif;
}

.pane-container {
  display: flex;
  flex-wrap: wrap;
}

.pane {
  height: 300px;
  display: flex;
  background: #e9e9e9;
  border-radius: 10px;
  margin: 20px;
  color: #474747;
}

.settings {
  width: 230px;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.config {
  margin: 4px;
}

.config label {
  width: 120px;
  margin-right: 10px;
  display: inline-block;
}

.config input {
  border: 0;
  border-radius: 2px;
  background: #d1d1d1;
  color: #474747;
  width: 80px;
  padding: 4px;
  text-align: center;
}

.config select {
  border: 0;
  border-radius: 2px;
  background: #d1d1d1;
  color: #474747;
  width: 80px;
  padding: 4px;
  text-align: center;
}

.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.avatar-icon {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  margin-right: 10px;
}

.url {
  margin-top: 118px;
}

.url input {
  width: 164px;
  padding: 4px;
  border: 0;
  border-radius: 2px;
  background: #d1d1d1;
  color: #474747;
  margin-right: 4px;
}

.url button {
  width: 40px;
  background: #d1d1d1;
  padding: 4px;
  border: 0;
  border-radius: 2px;
  color: #474747;
}

.preview {
  display: flex;
  height: 300px;
  width: 400px;
  border-radius: 10px;

  background: linear-gradient(
      45deg,
      #a9a9a9 25%,
      transparent 25%,
      transparent 75%,
      #a9a9a9 75%
    ),
    linear-gradient(
      45deg,
      #a9a9a9 25%,
      transparent 25%,
      transparent 75%,
      #a9a9a9 75%
    );
  background-color: #696969;
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;

  justify-content: center;
  align-items: center;

  overflow: hidden;
}

.global-settings {
  margin: 20px;
}

.notification {
  margin: 20px;
  padding: 10px;
  background: #ffc8a8;
  border-radius: 10px;
}

.notification.blue {
  background: #ccdbff;
}
